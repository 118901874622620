<template>
  <b-card
    class="flex-grow-1"
    title="Activity"
  >
    <b-tabs
      align="left"
      pills
    >
      <b-tab
        active
        title="Logs"
      >
        No activity detected.
      </b-tab>
      <b-tab>
        <template #title>
          <span>Disputes</span>
          <feather-icon
            v-if="localDisputes?.length > 0"
            icon="AlertTriangleIcon"
            class="ml-1"
          />
        </template>

        <b-row v-if="localDisputes?.length === 0">
          <b-col sm="12">
            No open disputes.
          </b-col>
        </b-row>

        <b-row
          v-for="dispute in localDisputes"
          v-else
          :key="dispute?.id"
        >
          <b-col sm="12">
            <b-badge
              size="sm"
              variant="light-danger"
              :to="{
                name: 'dashboard-tournament-matches',
                params: { id: $route.params?.id, tournamentId: dispute?.tournament?.id }
              }"
            >
              <strong>
                {{
              dispute?.opponent1?.participant
                ? dispute?.opponent1?.participant?.name
                : dispute?.opponent1?.name
                }}
              </strong>
              vs
              <strong>
                {{
              dispute?.opponent2?.participant
                ? dispute?.opponent2?.participant?.name
                : dispute?.opponent2?.name
                }}
              </strong>
              - {{ dispute?.tournament?.name }}
            </b-badge>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BBadge,
  BCard, BCol, BRow, BTab, BTabs,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BTab,
    BRow,
    BTabs,
    BCard,
    BBadge,
  },
  props: {
    disputes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localDisputes: this.disputes || [],
    }
  },
  watch: {
    disputes(newValue) {
      this.localDisputes = newValue
    },
  },
  methods: {
  },
}
</script>
