var render = function () {
  var _vm$organizerLocal;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "organizer-members-modal-edit",
      "no-close-on-backdrop": "",
      "size": "lg",
      "title": "Edit members and member roles"
    },
    on: {
      "hidden": _vm.onClose
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_c('div', {
          staticClass: "d-flex flex-grow-1 w-100"
        }, [_c('dismissible-message-box', {
          staticClass: "w-100 p-1",
          attrs: {
            "auto-dismiss": 6000,
            "show": _vm.showErrorNotifications,
            "variant": _vm.MessageType.ERROR
          },
          on: {
            "onDismissibleMessageBoxDismissed": function onDismissibleMessageBoxDismissed($event) {
              _vm.showErrorNotifications = false;
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "AlertCircleIcon"
          }
        }), _vm._v(" " + _vm._s(_vm.errors) + " ")], 1), _vm.showSuccessNotifications ? _c('dismissible-message-box', {
          staticClass: "w-100 p-1",
          attrs: {
            "auto-dismiss": 6000,
            "show": _vm.showSuccessNotifications,
            "variant": _vm.MessageType.SUCCESS
          },
          on: {
            "onDismissibleMessageBoxDismissed": function onDismissibleMessageBoxDismissed($event) {
              _vm.showSuccessNotifications = false;
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "InfoIcon"
          }
        }), _vm._v(" Organization members successfully updated ")], 1) : _vm._e(), _vm.showSuccessNotificationsInvite ? _c('dismissible-message-box', {
          staticClass: "w-100",
          attrs: {
            "auto-dismiss": 6000,
            "show": _vm.showSuccessNotificationsInvite,
            "variant": _vm.MessageType.SUCCESS
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "InfoIcon"
          }
        }), _vm._v(" New member invited ")], 1) : _vm._e()], 1), _c('div', {
          staticClass: "d-flex flex-grow-1 justify-content-between"
        }, [_c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": cancel
          }
        }, [_vm._v(" Close ")])], 1)];
      }
    }])
  }, [_c('b-container', [_c('b-row', {
    staticClass: "d-flex justify-space-around mb-2"
  }, [_c('b-col', [_vm._v(" When adding roles to members keep in mind that OWNER role has same access as you. Organizer role has access to tournaments. Staff role has access to livechat but no access to tournaments. Please invite only members you really trust as they are responsible to communication and tournaments. ")])], 1), _c('b-row', {
    staticClass: "d-flex justify-space-around"
  }, [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "items": (_vm$organizerLocal = _vm.organizerLocal) === null || _vm$organizerLocal === void 0 ? void 0 : _vm$organizerLocal.members,
      "fields": _vm.fields
    },
    scopedSlots: _vm._u([{
      key: "cell(role_id)",
      fn: function fn(data) {
        return [_c('v-select', {
          key: data.item.id,
          attrs: {
            "options": _vm.rolesOptions,
            "reduce": function reduce(role) {
              return role.code;
            },
            "label": "title"
          },
          model: {
            value: data.item.role_id,
            callback: function callback($$v) {
              _vm.$set(data.item, "role_id", $$v);
            },
            expression: "data.item.role_id"
          }
        })];
      }
    }, {
      key: "cell(user)",
      fn: function fn(data) {
        return [data.item.user_id ? _c('span', [_vm._v(_vm._s(_vm.getUsername(data.item.user_id)))]) : _c('v-select', {
          key: data.item.id,
          attrs: {
            "options": _vm.membersOptions,
            "reduce": function reduce(member) {
              return member.code;
            },
            "label": "title"
          },
          model: {
            value: data.item.user_id,
            callback: function callback($$v) {
              _vm.$set(data.item, "user_id", $$v);
            },
            expression: "data.item.user_id"
          }
        })];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(row) {
        return [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.saveItem(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "CheckCircleIcon",
            "size": "16"
          }
        })], 1), _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteItem(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "XCircleIcon",
            "size": "16"
          }
        })], 1)];
      }
    }])
  }), _c('b-button', {
    attrs: {
      "variant": "success"
    },
    on: {
      "click": _vm.addNewItem
    }
  }, [_vm._v(" + ")])], 1)], 1), _c('hr'), _c('b-row', {
    staticClass: "d-flex justify-space-around mt-2"
  }, [_c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('h5', [_vm._v("Invite new member")]), _c('validation-observer', {
    ref: "inviteEmailForm",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var invalid = _ref2.invalid;
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.handleInvite($event);
            }
          }
        }, [_c('b-row', {
          staticClass: "align-items-start"
        }, [_c('b-col', {
          attrs: {
            "cols": "9"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Email",
            "label-for": "email"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Email",
            "rules": "required|email"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var componentErrors2 = _ref3.errors;
              return [_c('b-form-input', {
                attrs: {
                  "id": "email",
                  "state": componentErrors2.length > 0 ? false : null,
                  "name": "login-email",
                  "placeholder": "john@example.com"
                },
                model: {
                  value: _vm.userEmail,
                  callback: function callback($$v) {
                    _vm.userEmail = $$v;
                  },
                  expression: "userEmail"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(" " + _vm._s(componentErrors2[0]) + " ")])];
            }
          }], null, true)
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "3"
          }
        }, [_c('spinning-button', {
          staticClass: "mt-2",
          attrs: {
            "disabled": invalid,
            "is-loading": _vm.isInviteLoading,
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.left",
            value: 'Send invitation',
            expression: "'Send invitation'",
            modifiers: {
              "hover": true,
              "left": true
            }
          }],
          attrs: {
            "icon": "SendIcon",
            "size": "16"
          }
        })], 1)], 1)], 1)], 1)];
      }
    }])
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }