var render = function () {
  var _vm$internalOrganizer, _vm$internalOrganizer2, _vm$organizer;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.organizer ? _c('organizer-members-edit-modal', {
    attrs: {
      "organizer": _vm.organizer
    }
  }) : _vm._e(), _c('b-card', {
    attrs: {
      "title": "Organizer team"
    }
  }, [_vm.organizerLoading ? _c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  }) : _c('div', [((_vm$internalOrganizer = _vm.internalOrganizer) === null || _vm$internalOrganizer === void 0 ? void 0 : (_vm$internalOrganizer2 = _vm$internalOrganizer.members) === null || _vm$internalOrganizer2 === void 0 ? void 0 : _vm$internalOrganizer2.length) === 0 ? _c('div', [_c('p', [_vm._v("No member yet.")])]) : _vm._e(), _vm._l((_vm$organizer = _vm.organizer) === null || _vm$organizer === void 0 ? void 0 : _vm$organizer.members, function (member) {
    var _member$user;
    return _c('b-badge', {
      key: member.id,
      staticClass: "mr-1",
      attrs: {
        "variant": "light-primary"
      }
    }, [_vm._v(" " + _vm._s((_member$user = member.user) === null || _member$user === void 0 ? void 0 : _member$user.name) + " ")]);
  }), _vm.canEdit ? _c('div', {
    staticClass: "mt-2"
  }, [_c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal:organizer-members-modal-edit",
      arg: "organizer-members-modal-edit"
    }],
    attrs: {
      "size": "sm",
      "variant": "primary"
    }
  }, [_vm._v(" Manage members ")])], 1) : _vm._e()], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }