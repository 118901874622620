<template>
  <div>
    <organizer-members-edit-modal
      v-if="organizer"
      :organizer="organizer"
    />

    <b-card title="Organizer team">
      <b-spinner
        v-if="organizerLoading"
        label="Loading..."
      />

      <div v-else>
        <div v-if="internalOrganizer?.members?.length === 0">
          <p>No member yet.</p>
        </div>

        <b-badge
          v-for="member in organizer?.members"
          :key="member.id"
          class="mr-1"
          variant="light-primary"
        >
          {{ member.user?.name }}
        </b-badge>

        <div
          v-if="canEdit"
          class="mt-2"
        >
          <b-button
            v-b-modal:organizer-members-modal-edit
            size="sm"
            variant="primary"
          >
            Manage members
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BBadge, BButton, BCard, BSpinner,
} from 'bootstrap-vue'
import OrganizerMembersEditModal from '@/views/dashboard/OrganizerMembersEditModal.vue'

export default {
  components: {
    OrganizerMembersEditModal,
    BCard,
    BBadge,
    BButton,
    BSpinner,
  },
  props: {

  },
  data: () => ({
    internalOrganizer: null,
  }),
  computed: {
    organizer() {
      return this.$store.getters['organizer/getDashboardOrganizer']
    },
    organizerLoading() {
      return this.$store.getters['organizer/isDashboardLoading']
    },
    me() {
      return this.$store.getters['user/getMe']
    },
    canEdit() {
      return this.me?.id === this.organizer?.owner?.id
    },
  },
}
</script>

<style lang="scss" scoped>
.badge {
  padding: 0.7rem;
}
</style>
