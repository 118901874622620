<template>
  <b-modal
    id="organizer-members-modal-edit"
    no-close-on-backdrop
    size="lg"
    title="Edit members and member roles"
    @hidden="onClose"
  >
    <template #modal-footer="{ cancel }">
      <div class="d-flex flex-grow-1 w-100">
        <dismissible-message-box
          :auto-dismiss="6000"
          :show="showErrorNotifications"
          :variant="MessageType.ERROR"
          class="w-100 p-1"
          @onDismissibleMessageBoxDismissed="showErrorNotifications = false"
        >
          <feather-icon
            class="mr-50"
            icon="AlertCircleIcon"
          />
          {{ errors }}
        </dismissible-message-box>
        <dismissible-message-box
          v-if="showSuccessNotifications"
          :auto-dismiss="6000"
          :show="showSuccessNotifications"
          :variant="MessageType.SUCCESS"
          class="w-100 p-1"
          @onDismissibleMessageBoxDismissed="showSuccessNotifications = false"
        >
          <feather-icon
            class="mr-50"
            icon="InfoIcon"
          />
          Organization members successfully updated
        </dismissible-message-box>

        <dismissible-message-box
          v-if="showSuccessNotificationsInvite"
          :auto-dismiss="6000"
          :show="showSuccessNotificationsInvite"
          :variant="MessageType.SUCCESS"
          class="w-100"
        >
          <feather-icon
            class="mr-50"
            icon="InfoIcon"
          />
          New member invited
        </dismissible-message-box>
      </div>

      <div class="d-flex flex-grow-1 justify-content-between">
        <b-button
          variant="outline-primary"
          @click="cancel"
        >
          Close
        </b-button>
      </div>
    </template>

    <b-container>
      <b-row class="d-flex justify-space-around mb-2">
        <b-col>
          When adding roles to members keep in mind that OWNER role has same
          access as you. Organizer role has access to tournaments. Staff role
          has access to livechat but no access to tournaments. Please invite
          only members you really trust as they are responsible to communication
          and tournaments.
        </b-col>
      </b-row>

      <b-row class="d-flex justify-space-around">
        <!-- Edit member roles -->
        <b-col
          sm="12"
        >
          <b-table
            :items="organizerLocal?.members"
            :fields="fields"
          >
            <template v-slot:cell(role_id)="data">
              <v-select
                :key="data.item.id"
                v-model="data.item.role_id"
                :options="rolesOptions"
                :reduce="role => role.code"
                label="title"
              />
            </template>

            <template v-slot:cell(user)="data">
              <!-- Check if user_id exists. If it does, display the user name; if not, show a dropdown to select a user -->
              <span v-if="data.item.user_id">{{ getUsername(data.item.user_id) }}</span>

              <v-select
                v-else
                :key="data.item.id"
                v-model="data.item.user_id"
                :options="membersOptions"
                :reduce="member => member.code"
                label="title"
              />
            </template>

            <template v-slot:cell(actions)="row">
              <b-button
                size="sm"
                class="mr-1"
                variant="primary"
                @click="saveItem(row.item)"
              >
                <feather-icon
                  icon="CheckCircleIcon"
                  size="16"
                />
              </b-button>

              <b-button
                size="sm"
                class="mr-1"
                variant="danger"
                @click="deleteItem(row.item)"
              >
                <feather-icon
                  icon="XCircleIcon"
                  size="16"
                />
              </b-button>
            </template>
          </b-table>

          <b-button
            variant="success"
            @click="addNewItem"
          >
            +
          </b-button>

        </b-col>

      </b-row>

      <hr>

      <b-row class="d-flex justify-space-around mt-2">
        <b-col
          md="6"
          sm="12"
        >
          <h5>Invite new member</h5>
          <validation-observer
            ref="inviteEmailForm"
            v-slot="{ invalid }"
          >
            <b-form @submit.prevent="handleInvite">
              <b-row class="align-items-start">
                <b-col cols="9">
                  <b-form-group
                    label="Email"
                    label-for="email"
                  >
                    <validation-provider
                      v-slot="{ errors: componentErrors2 }"
                      name="Email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="email"
                        v-model="userEmail"
                        :state="componentErrors2.length > 0 ? false : null"
                        name="login-email"
                        placeholder="john@example.com"
                      />
                      <small class="text-danger">
                        {{ componentErrors2[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <spinning-button
                    :disabled="invalid"
                    :is-loading="isInviteLoading"
                    class="mt-2"
                    type="submit"
                    variant="primary"
                  >
                    <feather-icon
                      v-b-tooltip.hover.left="'Send invitation'"
                      icon="SendIcon"
                      size="16"
                    />
                  </spinning-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>

    </b-container>
  </b-modal>
</template>

<script>
import {
  BButton,
  BCol,
  BContainer,
  BForm,
  BFormGroup,
  BFormInput,
  BModal,
  BRow, BTable,
  VBModal,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import DismissibleMessageBox from '@/components/DismissibleMessageBox.vue'
import MessageType from '@/constants/MessageType'
import SpinningButton from '@/components/SpinningButton.vue'
import store from '@/store'

export default {
  components: {
    SpinningButton,
    DismissibleMessageBox,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    BRow,
    BCol,
    BButton,
    BContainer,
    vSelect,
    BTable,

  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    organizer: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      fields: [{
        key: 'id',
        label: 'id',
      }, {
        key: 'user',
        label: 'member',
      }, {
        key: 'role_id',
        label: 'role',
      }, 'actions'],
      organizerLocal: JSON.parse(JSON.stringify(this.organizer)),
      membersOptions: this.organizer?.members?.map(member => ({
        title: member.user.name,
        code: member.user.id,
      })) || [],
      rolesOptions: [
        {
          title: 'Owner',
          code: 1,
        },
        {
          title: 'Organizer',
          code: 2,
        },
        {
          title: 'Staff',
          code: 3,
        },
      ],
      MessageType,
      errors: null,
      showErrorNotifications: false,
      showSuccessNotifications: false,
      showSuccessNotificationsInvite: false,
      isLoadingSave: [],
      isLoadingRemove: [],
      isInviteLoading: false,
      userEmail: null,
    }
  },
  computed: {},
  watch: {
    organizer(value) {
      this.organizerLocal = value

      this.membersOptions = value.members?.map(member => ({
        title: member.user.name,
        code: member.user.id,
      })) || []
    },
  },
  methods: {
    getUsername(userId) {
      const user = this.membersOptions.find(u => u.code === userId)
      return user ? user.title : ''
    },

    async saveItem(item) {
      this.errors = []
      this.showErrorNotifications = false
      this.showSuccessNotifications = false

      if (item.user_id == null || !item.role_id == null) {
        this.errors.push('No user | role selected!')

        this.showErrorNotifications = true

        return
      }

      const payload = {
        id: item.id || null,
        user_id: item.user_id,
        role_id: item.role_id,
        organizer_id: this.organizer.id,
      }

      const { errors, data } = await this.$api.organizer.organizerUpdateMember(payload)

      this.isLoading = []

      if (errors && errors.length > 0) {
        this.errors = 'Error saving member. Please try again or contact our support.'

        this.showErrorNotifications = true

        return
      }

      if (item.id == null) {
        const index = this.organizerLocal.members.indexOf(item)
        if (index > -1) {
          this.organizerLocal.members[index].id = data.id
        }
      }

      this.showSuccessNotifications = true
    },

    async deleteItem(item) {
      this.showErrorNotifications = false
      this.showSuccessNotifications = false

      if (item.id == null) {
        const index = this.organizerLocal.members.indexOf(item)
        if (index > -1) {
          this.organizerLocal.members.splice(index, 1)
        }

        return
      }

      const payload = {
        id: item.id,
        organizer_id: this.organizer.id,
      }

      const {
        errors,
      } = await this.$api.organizer.organizerRemoveMember(payload)

      this.isLoading = false

      if (errors && errors.length > 0) {
        this.errors = 'Error removing member. Please try again or contact our support.'

        this.showErrorNotifications = true

        return
      }

      const index = this.organizerLocal.members.indexOf(item)
      if (index > -1) {
        this.organizerLocal.members.splice(index, 1)
      }
    },

    addNewItem() {
      const newItem = {
        id: null,
        user_id: null,
        role_id: null,
      }

      this.organizerLocal.members.push(newItem)
    },

    async handleInvite() {
      this.errors = []
      this.showErrorNotifications = false
      this.showSuccessNotificationsInvite = false
      this.showSuccessNotifications = false

      const formValid = this.$refs.inviteEmailForm.validate()
      if (!formValid) {
        return
      }

      this.isInviteLoading = true

      const { errors } = await this.$api.organizer.organizerInviteMember({
        email: this.userEmail,
        organizer_id: this.organizer.id,
      })

      this.isInviteLoading = false

      if (errors && errors.length > 0) {
        this.errors = errors

        this.showErrorNotifications = true
      }

      this.showSuccessNotificationsInvite = true
    },

    async onClose() {
      this.organizerLocal = JSON.parse(JSON.stringify(this.organizer))

      this.membersOptions = this.organizer?.members?.map(member => ({
        title: member?.user?.name,
        code: member?.user?.id,
      })) || []

      this.userEmail = null
      this.errors = []
      this.showErrorNotifications = false
      this.showSuccessNotificationsInvite = false
      this.showSuccessNotifications = false
      this.isInviteLoading = false

      await store.dispatch('organizer/fetchDashboardOrganizer', this.$route.params.id)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
