<template>
  <div>
    <div v-if="organizerLoading">
      <b-row>
        <!-- Current organisation profile -->
        <b-col>
          <b-spinner label="Loading..." />
        </b-col>
      </b-row>
    </div>

    <div v-else>
      <b-row>
        <!-- Organizer profile -->
        <b-col md="9">
          <b-card>

            <b-row>
              <!-- My profile -->
              <b-col>
                <b-row v-if="!me">
                  <b-spinner
                    label="Loading..."
                  />
                </b-row>

                <b-row v-else>
                  <b-col
                    class="d-flex justify-content-center"
                    md="4"
                    sm="12"
                  >
                    <b-avatar
                      :src="me && me.avatar_image ? me.avatar_image : null"
                      size="90px"
                      square
                    />
                  </b-col>
                  <b-col
                    md="8"
                    sm="12"
                  >
                    <div>{{ me && me.name ? me.name : null }}</div>

                    <div>{{ (me && me.email && me.email) || null }}</div>

                    <div class="mt-1">
                      <b-button
                        size="sm"
                        to="/"
                        variant="primary"
                      >
                        Back to platform
                      </b-button>
                    </div>
                  </b-col>
                </b-row>

              </b-col>

              <b-col>
                <organizer-profile-edit-modal
                  :organizer="organizer"
                  :loading="organizerLoading"
                />
                <div>
                  <h5>Currently selected organisation</h5>
                </div>

                <div class="d-flex align-items-center">
                  <b-avatar
                    :src="organizer?.avatar_image || null"
                    class="mr-1"
                    size="50px"
                  />

                  <h5>
                    {{ organizer?.name || null }}
                  </h5>

                  <b-button
                    class="ml-2"
                    size="sm"
                    variant="primary"
                    @click="handleEditOrganizerClick"
                  >
                    edit
                  </b-button>
                </div>

                <div class="d-flex align-items-center mt-1">
                  <b-row class="flex-grow-1">
                    <b-col md="5">
                      <!-- Wallet Info -->
                      <div class="wallet-badge">
                        <b-badge
                          class="p-sm-1 font-medium-2"
                          variant="light-success"
                        >
                          {{ organizer?.wallet?.balance || 0 }} €
                        </b-badge>
                      </div>
                    <!--/ Wallet Info -->
                    </b-col>

                    <b-col md="5">
                      <!-- Wallet Actions -->
                      <b-button
                        disabled="disabled"
                        size="sm"
                        variant="primary"
                        @click="handleAddFundsClick"
                      >
                        Add funds
                      </b-button>

                      <b-button
                        class="mt-1"
                        disabled="disabled"
                        size="sm"
                        variant="primary"
                        @click="handleWithdrawFundsClick"
                      >
                        Withdraw funds
                      </b-button>
                    <!--/ Wallet Actions -->
                    </b-col>
                  </b-row>
                </div>

                <!-- create new tournament -->
                <div class="d-flex align-items-center mt-1 flex-grow-1">
                  <b-button
                    variant="primary"
                    @click="handleCreateNewTournament"
                  >
                    Create new tournament
                  </b-button>
                </div>
              </b-col>

              <!-- All owned organisations -->
              <b-col>
                <div>
                  <h5>Your other organisations</h5>
                </div>

                <div v-if="me?.organizers?.filter(org => org.id !== organizer.id).length === 0">
                  <p class="font-italic">
                    No other organizations available.
                  </p>
                </div>

                <div
                  v-for="organization in me?.organizers?.filter(org => org.id !== organizer.id)"
                  :key="organization.id"
                  class="d-flex mb-1"
                >
                  <b-col>
                    <b-avatar
                      :src="organization?.avatar_image"
                      size="30px"
                    />
                  </b-col>
                  <b-col md="6">
                    {{ organization.name }}
                  </b-col>

                  <b-col md="5">
                    <b-button
                      size="sm"
                      variant="primary"
                      @click="handleSwitchOrganization(organization.id)"
                    >
                      Switch
                    </b-button>
                  </b-col>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <!-- Organizer team -->
        <b-col md="3">
          <organizer-team />
        </b-col>
      </b-row>

      <!-- Tournaments && activity -->
      <b-row>
        <!-- Last tournaments -->
        <b-col
          lg="8"
          md="6"
          sm="12"
        >
          <b-card v-if="organizer?.tournaments?.length === 0">
            <div class="alert-body">
              <span class="mr-25"><feather-icon icon="sInfoIcon" /></span>
              <span class="ml-25">No planned tournaments yet.</span>
            </div>
          </b-card>

          <b-row>
            <edit-tournament-staff-modal
              :organizer-members="organizer?.members"
              :tournament="currentTournament"
              @tournamentStaffUpdated="handleTournamentStaffUpdated"
            />

            <b-col
              v-for="tournament in organizer?.tournaments"
              :key="tournament.id"
              lg="4"
              sm="12"
              class="d-flex align-items-stretch"
            >
              <organizer-tournament-card
                :key="tournament.id"
                :tournament="tournament"
                @editTournamentStaffClick="showStaffEditModal"
              />
            </b-col>
          </b-row>
        </b-col>

        <!-- Activity -->
        <b-col
          lg="4"
          md="6"
          sm="12"
        >
          <organizer-dashboard-activity :disputes="disputes" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BButton, BCard, BCol, BRow, BSpinner,
} from 'bootstrap-vue'
import OrganizerTeam from '@/views/dashboard/OrganizerTeam.vue'
import OrganizerTournamentCard from '@/views/dashboard/tournament/OrganizerTournamentCard.vue'
import OrganizerDashboardActivity from '@/views/dashboard/OrganizerDashboardActivity.vue'
import OrganizerProfileEditModal from '@/views/dashboard/OrganizerProfileEditModal.vue'
import EditTournamentStaffModal from '@/views/dashboard/EditTournamentStaffModal.vue'

export default {
  components: {
    EditTournamentStaffModal,
    OrganizerDashboardActivity,
    OrganizerTournamentCard,
    OrganizerTeam,
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BBadge,
    BSpinner,
    OrganizerProfileEditModal,
  },
  data: () => ({
    filterActivitiesBy: 'all',
    tournaments: [],
    loading: true,
    myOrganizations: [],
    currentTournamentStaff: null,
    currentTournamentId: null,
    currentTournament: null,
  }),
  computed: {
    me() {
      return this.$store.getters['user/getMe']
    },
    organizer() {
      return this.$store.getters['organizer/getDashboardOrganizer']
    },
    organizerLoading() {
      return this.$store.getters['organizer/isDashboardLoading']
    },
    disputes() {
      const matches = []

      // eslint-disable-next-line array-callback-return,no-unused-expressions
      this.organizer?.tournaments?.forEach(tournament => tournament.matches?.map(match => {
        if (match.status_id === 6) {
          matches.push({ ...match, tournament })
        }
      }))
      return matches
    },
  },
  methods: {
    handleSwitchOrganization(id) {
      this.$router.push({
        name: 'dashboard',
        params: {
          id,
        },
      })

      window.location.reload()
    },
    handleCreateNewTournament() {
      this.$router.push({
        name: 'dashboard-tournaments-new',
      })
    },

    handleAddFundsClick() {
      this.$bvModal.show('match-result-admin-modal')
      // this.$bvModal.show('match-info-modal')
    },

    handleWithdrawFundsClick() {
    },

    handleEditOrganizerClick() {
      this.$bvModal.show('organizer-profile-modal-edit')
    },

    showStaffEditModal(tournament) {
      this.currentTournament = tournament

      this.$bvModal.show('edit-tournament-staff-modal')
    },

    async handleTournamentStaffUpdated() {
      await this.$store.dispatch('organizer/fetchDashboardOrganizer', this.$route.params.id)
    },
  },
}
</script>

<style lang="scss">
.wallet-badge {
  .badge {
    width: 100%;
  }
}

.wallet-operations {
  margin-top: 20px;
}
</style>
