var render = function () {
  var _vm$localDisputes2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "flex-grow-1",
    attrs: {
      "title": "Activity"
    }
  }, [_c('b-tabs', {
    attrs: {
      "align": "left",
      "pills": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "active": "",
      "title": "Logs"
    }
  }, [_vm._v(" No activity detected. ")]), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        var _vm$localDisputes;
        return [_c('span', [_vm._v("Disputes")]), ((_vm$localDisputes = _vm.localDisputes) === null || _vm$localDisputes === void 0 ? void 0 : _vm$localDisputes.length) > 0 ? _c('feather-icon', {
          staticClass: "ml-1",
          attrs: {
            "icon": "AlertTriangleIcon"
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  }, [((_vm$localDisputes2 = _vm.localDisputes) === null || _vm$localDisputes2 === void 0 ? void 0 : _vm$localDisputes2.length) === 0 ? _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_vm._v(" No open disputes. ")])], 1) : _vm._l(_vm.localDisputes, function (dispute) {
    var _vm$$route$params, _dispute$tournament, _dispute$opponent, _dispute$opponent2, _dispute$opponent2$pa, _dispute$opponent3, _dispute$opponent4, _dispute$opponent5, _dispute$opponent5$pa, _dispute$opponent6, _dispute$tournament2;
    return _c('b-row', {
      key: dispute === null || dispute === void 0 ? void 0 : dispute.id
    }, [_c('b-col', {
      attrs: {
        "sm": "12"
      }
    }, [_c('b-badge', {
      attrs: {
        "size": "sm",
        "variant": "light-danger",
        "to": {
          name: 'dashboard-tournament-matches',
          params: {
            id: (_vm$$route$params = _vm.$route.params) === null || _vm$$route$params === void 0 ? void 0 : _vm$$route$params.id,
            tournamentId: dispute === null || dispute === void 0 ? void 0 : (_dispute$tournament = dispute.tournament) === null || _dispute$tournament === void 0 ? void 0 : _dispute$tournament.id
          }
        }
      }
    }, [_c('strong', [_vm._v(" " + _vm._s(dispute !== null && dispute !== void 0 && (_dispute$opponent = dispute.opponent1) !== null && _dispute$opponent !== void 0 && _dispute$opponent.participant ? dispute === null || dispute === void 0 ? void 0 : (_dispute$opponent2 = dispute.opponent1) === null || _dispute$opponent2 === void 0 ? void 0 : (_dispute$opponent2$pa = _dispute$opponent2.participant) === null || _dispute$opponent2$pa === void 0 ? void 0 : _dispute$opponent2$pa.name : dispute === null || dispute === void 0 ? void 0 : (_dispute$opponent3 = dispute.opponent1) === null || _dispute$opponent3 === void 0 ? void 0 : _dispute$opponent3.name) + " ")]), _vm._v(" vs "), _c('strong', [_vm._v(" " + _vm._s(dispute !== null && dispute !== void 0 && (_dispute$opponent4 = dispute.opponent2) !== null && _dispute$opponent4 !== void 0 && _dispute$opponent4.participant ? dispute === null || dispute === void 0 ? void 0 : (_dispute$opponent5 = dispute.opponent2) === null || _dispute$opponent5 === void 0 ? void 0 : (_dispute$opponent5$pa = _dispute$opponent5.participant) === null || _dispute$opponent5$pa === void 0 ? void 0 : _dispute$opponent5$pa.name : dispute === null || dispute === void 0 ? void 0 : (_dispute$opponent6 = dispute.opponent2) === null || _dispute$opponent6 === void 0 ? void 0 : _dispute$opponent6.name) + " ")]), _vm._v(" - " + _vm._s(dispute === null || dispute === void 0 ? void 0 : (_dispute$tournament2 = dispute.tournament) === null || _dispute$tournament2 === void 0 ? void 0 : _dispute$tournament2.name) + " ")])], 1)], 1);
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }