var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "organizer-profile-modal-edit",
      "no-close-on-backdrop": "",
      "size": "xl",
      "title": "Edit organizer"
    },
    on: {
      "ok": _vm.handleOk,
      "hidden": _vm.onClose
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel,
          ok = _ref.ok;
        return [_c('b-row', {
          staticClass: "w-100 mr-1 ml-1 justify-content-start"
        }, [_c('dismissible-message-box', {
          staticClass: "p-1 w-100",
          attrs: {
            "auto-dismiss": 6000,
            "show": _vm.showMessageBox,
            "variant": _vm.messageboxType
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "InfoIcon"
          }
        }), _vm._v(" " + _vm._s(_vm.message) + " ")], 1)], 1), _c('b-row', {
          staticClass: "justify-content-between w-100 mr-1 ml-1"
        }, [_c('spinning-button', {
          attrs: {
            "is-loading": _vm.isLoading,
            "type": "submit",
            "variant": "primary"
          },
          nativeOn: {
            "click": function click($event) {
              return ok($event);
            }
          }
        }, [_vm._v(" Update profile ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": cancel
          }
        }, [_vm._v(" Close ")])], 1)];
      }
    }])
  }, [_c('b-container', [_c('validation-observer', {
    ref: "observer"
  }, [_c('b-form', {
    attrs: {
      "enctype": "multipart/form-data"
    },
    on: {
      "submit": function submit($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.handleSubmit($event);
      }
    }
  }, [_c('b-row', {
    staticClass: "d-flex justify-space-around"
  }, [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('h5', [_vm._v("Organizer info")]), _c('b-form-group', [_c('label', [_vm._v("Name")]), _c('validation-provider', {
    attrs: {
      "name": "name",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var componentErrors = _ref2.errors;
        return [_c('b-form-input', {
          attrs: {
            "name": "name",
            "placeholder": "eSports"
          },
          model: {
            value: _vm.organizerLocal.name,
            callback: function callback($$v) {
              _vm.$set(_vm.organizerLocal, "name", $$v);
            },
            expression: "organizerLocal.name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(componentErrors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v("Email")]), _c('validation-provider', {
    attrs: {
      "name": " email",
      "rules": "email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var componentErrors = _ref3.errors;
        return [_c('b-form-input', {
          attrs: {
            "name": "email",
            "placeholder": "esports@example.com"
          },
          model: {
            value: _vm.organizerLocal.email,
            callback: function callback($$v) {
              _vm.$set(_vm.organizerLocal, "email", $$v);
            },
            expression: "organizerLocal.email"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(componentErrors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('h5', [_vm._v("Profile")]), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "team-avatar mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "src": _vm.organizerLocal.avatar_image,
      "button": "",
      "size": "100px",
      "square": "",
      "variant": "primary"
    },
    on: {
      "click": _vm.openAvatarFilePopup
    }
  })], 1), _c('div', {
    staticClass: "team-avatar-upload-container"
  }, [_c('div', {
    staticClass: "d-flex mb-1 flex-wrap justify-content-around"
  }, [_c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.openAvatarFilePopup
    }
  }, [_vm._v(" Upload ")]), _c('b-button', {
    attrs: {
      "cla": "",
      "size": "sm",
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.clearAvatar
    }
  }, [_vm.removingAvatar ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _c('span', [_vm._v("Reset")])], 1)], 1), _c('label', [_vm._v("Allowed JPG, GIF or PNG. Max size of 1MB")]), _c('b-form-file', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "avatarFileInput",
    staticClass: "mt-1",
    attrs: {
      "plain": ""
    },
    model: {
      value: _vm.avatar,
      callback: function callback($$v) {
        _vm.avatar = $$v;
      },
      expression: "avatar"
    }
  })], 1)])]), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('h5', [_vm._v("Social media")]), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "discord"
    }
  }, [_vm._v("Discord")]), _c('b-form-input', {
    attrs: {
      "name": "discord",
      "placeholder": "Enter username, like Clash#1337"
    },
    model: {
      value: _vm.organizerLocal.discord.url,
      callback: function callback($$v) {
        _vm.$set(_vm.organizerLocal.discord, "url", $$v);
      },
      expression: "organizerLocal.discord.url"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "instagram"
    }
  }, [_vm._v("Instagram")]), _c('b-form-input', {
    attrs: {
      "name": "instagram",
      "placeholder": "Enter instagram username"
    },
    model: {
      value: _vm.organizerLocal.instagram.url,
      callback: function callback($$v) {
        _vm.$set(_vm.organizerLocal.instagram, "url", $$v);
      },
      expression: "organizerLocal.instagram.url"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "twitter"
    }
  }, [_vm._v("Twitter")]), _c('b-form-input', {
    attrs: {
      "name": "twitter",
      "placeholder": "Enter facebook username"
    },
    model: {
      value: _vm.organizerLocal.twitter.url,
      callback: function callback($$v) {
        _vm.$set(_vm.organizerLocal.twitter, "url", $$v);
      },
      expression: "organizerLocal.twitter.url"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "facebook"
    }
  }, [_vm._v("Facebook")]), _c('b-form-input', {
    attrs: {
      "name": "facebook",
      "placeholder": "Enter facebook username"
    },
    model: {
      value: _vm.organizerLocal.facebook.url,
      callback: function callback($$v) {
        _vm.$set(_vm.organizerLocal.facebook, "url", $$v);
      },
      expression: "organizerLocal.facebook.url"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }